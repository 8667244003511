import { Link as RouterLink, type LinkProps as RouterLinkProps } from '@remix-run/react';
import { forwardRef } from 'react';

import { type UnstyledButtonProps, UnstyledButton, List, type ListItemProps } from '@mantine/core';
import { clsx } from 'clsx';

import classes from './ListItemButton.module.css';

export interface ListItemButtonProps extends
  UnstyledButtonProps,
  Omit<React.ComponentPropsWithoutRef<typeof List.Item>, keyof UnstyledButtonProps> {}

export const ListItemButton = forwardRef<HTMLAnchorElement, ListItemButtonProps>((props, ref) => {
  const { children, ...restProps } = props;
  return (
    <UnstyledButton ref={ref} component={List.Item} classNames={{ root: classes.root }} {...restProps}>
      {children}
    </UnstyledButton>);

});
ListItemButton.displayName = 'ListItemButton';

export interface ListItemButtonLinkProps extends ListItemProps {
  to: RouterLinkProps['to'];
  replace?: RouterLinkProps['replace'];
  prefetch?: RouterLinkProps['prefetch'];
  linkProps?: Omit<RouterLinkProps, 'to' | 'replace'>;
}

export const ListItemButtonLink = forwardRef<HTMLLIElement, ListItemButtonLinkProps>((props, ref) => {
  const { children, to, replace, prefetch, linkProps, ...restProps } = props;
  return (
    <List.Item ref={ref} classNames={{ itemWrapper: classes.itemWrapper, itemLabel: classes.itemLabel }} {...restProps}>
      <RouterLink
        to={to}
        replace={replace}
        prefetch={prefetch}
        className={clsx(classes.link, linkProps?.className)}
        {...linkProps}>

        {children}
      </RouterLink>
    </List.Item>);

});
ListItemButtonLink.displayName = 'ListItemButtonLink';